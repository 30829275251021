import BasePlugin from '../BasePlugin'

export default class ReloadMap extends BasePlugin {
  async execute () {
    let map = this.context.getDashboardComponents()['component_9607ca04-12e4-47a2-8524-b1c9f60909ad'][0]
    map.yMaps().geoObjects.removeAll()

    this.context.getModel()['attr_2618_'] = null
    this.context.getModel()['attr_2385_'] = null
    this.context.getModel()['attr_2617_'] = null
  }
}
